import { ReactComponent as Ilustration } from "../../assets/images/login_nurasign.svg";
import { setLoggedUser, setAuthenticated } from "../../store/reducer";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import Background from "../../assets/images/login_background.svg";
import { URI, URI_API, getHeaders } from "../../utils/config";
import { ILoggedUser } from "../../store/interfaces";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import "./loginView.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CompleteInformationModal from "../../widgets/completeInformationModal/completeInformationModal";

export default function LoginView() {
    const { instance } = useMsal();

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [completeInformationModalOpen, setCompleteInformationModalOpen] = useState(false);
    const [resolvePromise, setResolvePromise] = useState<((value: { nominaNumber: string; classX: string; vitalSupport: string }) => void) | null>(
        null
    );

    const dispatch: Dispatch<any> = useDispatch();
    let navigator = useNavigate();

    const handleOpenCompleteInformationModal = (): Promise<{ nominaNumber: string; classX: string; vitalSupport: string }> => {
        return new Promise((resolve) => {
            setResolvePromise(() => resolve);
            setCompleteInformationModalOpen(true);
        });
    };

    const handleContinue = (nominaNumber: string, classX: string, vitalSupport: string) => {
        if (resolvePromise) {
            resolvePromise({ nominaNumber, classX, vitalSupport });
        }
        setCompleteInformationModalOpen(false);
    };

    const handleCloseModal = () => {
        setCompleteInformationModalOpen(false);
    };

    const initializeSignIn = (): void => {
        setInProgress(true);
        instance.loginPopup().then(async (res) => {
            let res_photo: string = "";

            const res_ad_groups = await fetch("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", {
                headers: {
                    Authorization: "Bearer " + res.accessToken,
                },
            }).then((data) => data.json());

            const ad_groups: any[] = [];
            res_ad_groups.value.forEach((group: any) => {
                ad_groups.push(group.displayName);
            });
            
            const positions_available = ["nura_jefe", "nura_super", "nura_coord"];
            const hospitals_available = [
                "nura_sur",
                "nura_cumbres",
                "nura_conchita",
                "nura_satlillo",
                "nura_parque",
                "nura_betania",
                "nura_upaep",
                "nura_hfm",
                "nura_altagracia",
                "nura_reynosa",
                "nura_vidriera",
                "nura_sn",
                "nura_sp",
                "nura_ae",
            ];
            const areas_available = ["nurap"];

            const position_raw = ad_groups.filter((group) => positions_available.some((position) => group.includes(position)));
            const hospital_raw = ad_groups.filter((group) => hospitals_available.some((hospital) => group.includes(hospital)));
            const areas_raw = ad_groups.filter((group) => areas_available.some((area) => group.includes(area)));

            const position = position_raw[0];

            const hospital_maping: { [key: string]: string } = {
                nura_sur: "Sur",
                nura_cumbres: "Cumbres",
                nura_conchita: "Conchita",
                nura_satlillo: "Saltillo",
                nura_parque: "Del Parque",
                nura_betania: "Betania",
                nura_upaep: "UPAEP",
                nura_hfm: "HFM",
                nura_altagracia: "Altagracia",
                nura_reynosa: "Reynosa",
                nura_vidriera: "Vidriera",
                nura_sn: "San Nicolás",
                nura_sp: "San Pedro",
                nura_ae: "Alta Especialidad",
            };

            const areas_maping: { [key: string]: string } = {
                nurap_pisouno: "PISO 1",
                nurap_pisodos: "PISO 2",
                nurap_pisotres: "PISO 3",
                nurap_pisocuatro: "PISO 4",
                nurap_pisocinco: "PISO 5",
                nurap_pisoseis: "PISO 6",
                nurap_intensivos_adulto: "CUIDADOS INTENSIVOS ADULTO",
                nurap_intensivos_pediatria: "CUIDADOS INTENSIVOS PEDIATRIA",
                nurap_intermedios: "CUIDADOS INTERMEDIOS",
                nurap_obstetricia: "OBSTETRICIA",
                nurap_soporte: "SOPORTE",
            };

            let hospital: any[] = [];
            let areas: any[] = [];

            // Si es superusuario, asignar todas las áreas de todos los hospitales
            if (position === "nura_super") {
                hospital = hospitals_available.map((hospital) => hospital_maping[hospital] || hospital);

                areas = Object.values(areas_maping);
                // Si no es superusuario, tomar los permisos de los grupos del active directory
            } else {
                hospital = hospital_raw.map((hospital) => hospital_maping[hospital] || hospital);

                areas = areas_raw.map((area) => areas_maping[area] || area);
            }

            const user_exists = await fetch(
                `${URI_API}/user_exists`,
                getHeaders("POST", {
                    id_token_claims: res.idTokenClaims,
                })
            ).then((user_exists) => user_exists.json());

            if (user_exists === false) {
                const { nominaNumber, classX, vitalSupport } = await handleOpenCompleteInformationModal();
                await fetch(
                    `${URI_API}/create_user`,
                    getHeaders("POST", {
                        id_token_claims: res.idTokenClaims,
                        nominaNumber: nominaNumber,
                        classx: classX,
                        vitalSupport: vitalSupport,
                        hospital: hospital[0],
                    })
                );
            }

            await Promise.all([
                // Get Token from our API
                fetch(
                    `${URI}/getAToken`,
                    getHeaders("POST", {
                        access_token: res.accessToken,
                        id_token_claims: res.idTokenClaims,
                        nura_hospital: hospital,
                        nura_area: areas,
                    })
                ),

                // Get photo
                fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
                    headers: {
                        Authorization: "Bearer " + res.accessToken,
                    },
                }).then((res_photo) => res_photo.blob()),
            ]).then((result) => {
                res_photo = URL.createObjectURL(result[1]);
            });

            /// Create Session in our API
            const res_user = await fetch(`${URI_API}/session_user`, getHeaders()).then((res_user) => res_user.json());

            const user: ILoggedUser = {
                id: res_user.id,
                fullName: res_user.display_name,
                job_title: res_user.job_title,
                email: res_user.upn,
                type: res_user.type,
                nurse_id: res_user.nurse_id,
                areas: res_user.areas,
                imageURL: res_photo,
                hospital_id: res_user.hospital_id,
                position: position,
            };

            setInProgress(false);
            dispatch(setLoggedUser(user));
            dispatch(setAuthenticated(true));
            navigator("/adjust");
        });
    };

    return (
        <div id="login-background" style={{ backgroundImage: `url(${Background})` }}>
            <div id="login-modal">
                <div id="login-modal-body">
                    <img src={require("../../assets/images/nurasign_color.png")}></img>
                    <div>
                        <h1>Bienvenido</h1>
                        <h3>Inicia sesión para continuar</h3>
                        <Button onClick={initializeSignIn}>Iniciar sesión</Button>
                    </div>
                </div>
                <div>
                    <Ilustration></Ilustration>
                </div>
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={inProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {completeInformationModalOpen && (
                <CompleteInformationModal
                    showComponent={completeInformationModalOpen}
                    closeComponent={handleCloseModal}
                    onContinue={handleContinue} // Pasamos la función que resuelve la promesa
                />
            )}
        </div>
    );
}
