import { useState } from "react";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { Modal, Box, TextField, Typography, MenuItem } from "@mui/material";

export default function CompleteInformationModal(props: {
    showComponent: boolean;
    closeComponent: any;
    onContinue: (nominaNumber: string, classx: string, vitalSupport: string) => void;
}) {

    const [nominaNumber, setNominaNumber] = useState<string>("");
    const [classX, setClassx] = useState<string>("");
    const [vitalSupport, setVitalSupport] = useState<string>("");

    const classxOptions = ["COORD", "ENF ESP", "ENF SUB", "ENF A", "ENF B", "ENF C", "AUX A", "AUX B", "AUX C"];
    const vitalSupportOptions = ["BLS", "PALS", "NALS", "PALS NALS"];

    const handleClassxChange = (e: any) => {
        setClassx(e.target.value);
    }

    const handleNominaChange = (e: any) => {
        setNominaNumber(e.target.value);
    }

    const handleVitalSupportChange = (e: any) => {
        setVitalSupport(e.target.value);
    }

    const continueLoginFlow = () => {
        props.onContinue(nominaNumber, classX, vitalSupport);
    }

    return (
        <Modal open={props.showComponent} onClose={props.closeComponent}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: window.innerWidth > 1650 ? "30vw" : "35vw",
                    height: window.innerHeight > 800 ? "45vh" : "58vh",
                    bgcolor: "#FFFFFF",
                    // border: "1px solid transparent",
                    boxShadow: 24,
                    p: "2%",
                    borderRadius: "16px",
                }}
            >
                <div className="modalTitleContainer">
                    <p className="modalTitle">Completa tus datos para continuar.</p>
                </div>
                <div style={{ padding: "5%" }}>
                    <div style={{ marginTop: '1%', width: '100%'}}>
                        <div>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Manrope',
                                marginBottom: '2%'
                            }}>Número de nómina</Typography>
                            <TextField onChange={handleNominaChange} id="SelectNominaNumber" sx={{ marginBottom: '2%'}} variant="outlined" size="small" fullWidth />
                            <Typography sx={{
                                fontSize: '12px',
                                fontFamily: 'Manrope'
                            }}>Ten en cuenta que este dato no podrá ser editado más adelante.</Typography>
                        </div>
                        <div style={{ marginTop: '3%'}}>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Manrope',
                                marginBottom: '2%'
                            }}>Categoría</Typography>
                            <TextField onChange={handleClassxChange} defaultValue="" id="SelectClassx" select variant="outlined" size="small" fullWidth>
                                {classxOptions.map((classx) => (
                                    <MenuItem key={classx} value={classx}>
                                        {classx}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div style={{ marginTop: '3%'}}>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Manrope',
                                marginBottom: '2%'
                            }}>Soporte vital</Typography>
                            <TextField onChange={handleVitalSupportChange} defaultValue="" id="SelectVitalSupport" select variant="outlined" size="small" fullWidth>
                                {vitalSupportOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "2%",
                    }}
                >
                    <button
                        style={{
                            borderRadius: "16px",
                            border: "1px solid transparent",
                            backgroundColor: "transparent",
                            color: "#A2A2A2",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            padding: "1% 5% 1% 5%",
                            cursor: "pointer",
                        }}
                        type="button"
                        onClick={props.closeComponent}
                    >
                        CANCELAR
                    </button>
                    <button
                        style={{
                            borderRadius: "16px",
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#62098C",
                            color: "#FFFFFF",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            padding: "1% 5% 1% 5%",
                            cursor: "pointer",
                            marginLeft: "2%",
                        }}
                        type="button"
                        onClick={continueLoginFlow}
                    >
                        Continuar
                    </button>
                </div>
            </Box>
        </Modal>
    );
}
