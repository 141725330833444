import { ReactComponent as EditPatientIcon } from "../assets/icons/editPatient.svg";
import { emptySelectedPatient, ILoggedUser } from "../store/interfaces";
import { PatientViewContext } from "../contexts/patientViewContext";
import { setSelectedPatient, AppState } from "../store/reducer";
import { useEffect, useState, useContext } from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { URI_API, getHeaders } from "../utils/config";
import { Dispatch } from "redux";
import "./patientsTable.scss";

export default function PatientsToAssignTable(props: {
    showAssignDrawer: any;
    assignDrawer: any;
}) {
    const {
        setSelectedUnassignedPatient,
        setSelectedArea,
        query,
        setPatients,
        patients,
    } = useContext(PatientViewContext);

    const [tableLoading, setTableLoading] = useState(true);
    const dispatch: Dispatch<any> = useDispatch();

    const loggedUser: ILoggedUser = useSelector(
        (state: AppState) => state.loggedUser
    );

    useEffect(() => {
        fetch(`${URI_API}/patients/unassigned`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setPatients(body);
                setTableLoading(false);
            });
        fetch(`${URI_API}/patients/1`, getHeaders("POST", {})).then((res) =>
            res.json()
        );
    }, []);

    useEffect(() => {
        fetch(`${URI_API}/patients/unassigned`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setPatients(body);
                setTableLoading(false);
            });
    }, [props.assignDrawer]);

    function mergeObjects(obj1: any, obj2: any): any {
        const mergedObj = { ...obj1 };

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj2[key] !== null && obj2[key] !== "") {
                    mergedObj[key] = obj2[key];
                }
            }
        }

        return mergedObj;
    }

    const selectPatient = (patient: any): void => {
        let selectedPatient: any = {
            id: patient["id"],
            name: patient["name"],
            attentionId: patient["patient_number"],
            room: patient["room"],
            dateAdmission: patient["date_of_admission"],
            area_id: patient["area_id"],
            areaName: patient["areaname"],
        };
        selectedPatient = mergeObjects(emptySelectedPatient, selectedPatient);
        dispatch(setSelectedPatient(selectedPatient));
    };

    return (
        <>
            {!tableLoading && (
                <table className="mainTable">
                    {patients
                        .sort((a, b) => {
                            const stringA = a.date_of_admission
                                .split("/")
                                .reverse();
                            const stringB = b.date_of_admission
                                .split("/")
                                .reverse();
                            const fechaA = new Date(
                                parseInt(stringA[0]) > 2000
                                    ? parseInt(stringA[0])
                                    : parseInt(stringA[0]) + 2000,
                                parseInt(stringA[1]) - 1,
                                parseInt(stringA[2])
                            );
                            const fechaB = new Date(
                                parseInt(stringB[0]) > 2000
                                    ? parseInt(stringB[0])
                                    : parseInt(stringB[0]) + 2000,
                                parseInt(stringB[1]) - 1,
                                parseInt(stringB[2])
                            );
                            return fechaB.getTime() - fechaA.getTime();
                        })
                        .filter((patient) => {
                            if (query === "") {
                                return patient;
                            } else if (
                                patient.name
                                    .toUpperCase()
                                    .includes(query.toUpperCase()) ||
                                patient.room
                                    .toString()
                                    .toUpperCase()
                                    .includes(query.toUpperCase()) ||
                                patient.areaname
                                    .toUpperCase()
                                    .includes(query.toUpperCase())
                            ) {
                                return patient;
                            }
                        })
                        .map((patient) => {
                            return (
                                <tr>
                                    <td style={{width: '5%'}}>
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                }}
                                            >
                                                <Tooltip
                                                    title="Asignar paciente"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <button
                                                        className="button-icon"
                                                        onClick={() => {
                                                            selectPatient(
                                                                patient
                                                            );
                                                            setSelectedUnassignedPatient(
                                                                patient
                                                            );
                                                            setSelectedArea(
                                                                patient.area_id
                                                            );
                                                            props.showAssignDrawer();
                                                        }}
                                                    >
                                                        <EditPatientIcon></EditPatientIcon>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{width: '5%'}}>{patient.patient_number}</td>
                                    <td style={{width: '40.5%'}}>{patient.name}</td>
                                    <td style={{width: '30%'}}>{patient.areaname}</td>
                                    {loggedUser.position === "nura_super" && (
                                        <td style={{width: '25%'}}>{patient.hospital_name}</td>
                                    )}
                                    <td>{patient.room}</td>
                                </tr>
                            );
                        })}
                </table>
            )}
            {tableLoading && (
                <LinearProgress
                    color="secondary"
                    style={{
                        marginTop: "1%",
                    }}
                ></LinearProgress>
            )}
        </>
    );
}
