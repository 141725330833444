import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { URI_API, getHeaders } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { ILoggedUser } from "../../store/interfaces";
import Shift from "../../utils/shiftFunctions";
import { AppState } from "../../store/reducer";
import { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import moment from "moment";

export default function NurseFunctionModal(props: {
    showComponent: boolean;
    closeComponent: any;
}) {
    const [tabOption, setTabOption] = useState<string>("asignacion");
    const [selectedFunction, setSelectedFunction] = useState("");
    const [selectedNurse, setSelectedNurse] = useState("");
    const [assignedFunctions, setAssignedFunctions] = useState<any[]>([]);
    const [availableNurses, setAvailableNurses] = useState<Array<any>>([]);
    const [functions, setFunctions] = useState<Array<any>>([]);

    // Using for Assigned Nurses
    const [selectedNurses, setSelectedNurses] = useState<Array<String>>([]);

    const loggedUser: ILoggedUser = useSelector(
        (state: AppState) => state.loggedUser
    );

    const getNursesByDay = (shift: any, shiftCount: any) => {
        let date = new Date();

        const hours = date.getHours();

        let date_str = new Date(
            date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate()
        );

        let parsedStr = "";
        if (hours >= 0 && hours < 7 && shift.key === "nocturno") {
            const day = 1000 * 60 * 60 * 24;
            const yesterday = new Date(date_str.getTime() - day);
            parsedStr = yesterday.toISOString().slice(0, 10);
        } else {
            parsedStr = date_str.toISOString().slice(0, 10);
        }
        fetch(
            `${URI_API}/nursing_calendarRea/${parsedStr}/${shift.key}`,
            getHeaders()
        )
            .then((res) => res.json())
            .then((body) => {
                setAvailableNurses(body);
            });
    };

    const getFunctions = () => {
        fetch(`${URI_API}/functions`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setFunctions(body);
            });
    };

    const getAssignedFunctions = () => {
        fetch(`${URI_API}/assigned_functions/${loggedUser.hospital_id}`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setAssignedFunctions(body);
            });
    };

    useEffect(() => {
        fetch(`${URI_API}/assigned_functions/${loggedUser.hospital_id}`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setAssignedFunctions(body);
            });
        fetch(`${URI_API}/current_work_schedule_name`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                let currentShift = Shift.Current(body);
                let shift = Shift.AsOption(currentShift);
                getNursesByDay(shift, body);
                getFunctions();
                getAssignedFunctions();
            });
    }, [props.showComponent]);

    const clearFields = () => {
        setSelectedFunction("");
        setSelectedNurse("");
        setSelectedNurses([]);
    };

    const assignFunction = () => {
        if (selectedFunction === "" || selectedNurse === "") {
            toast("Favor de llenar todos los campos.", {
                type: "error",
                containerId: "NFM",
            });
            return;
        }

        let data = {
            function_id: selectedFunction,
            nurse_id: selectedNurse,
            date: new Date().toISOString().substring(0, 10),
            is_active: "TRUE",
        };
        fetch(`${URI_API}/assigned_functions`, getHeaders("POST", data))
            .then((res) => res.json())
            .then((body) => {
                toast("Funcionalidad agregada", {
                    type: "success",
                    containerId: "NFM",
                });
                props.closeComponent();
                clearFields();
            });
    };

    const deleteFunction = async () => {
        if (selectedNurses.length === 0) {
            toast("Selecciona al menos una función.", {
                type: "error",
                containerId: "NFM",
            });
            return;
        }

        const requests = selectedNurses.map((selectedNurse) => {
            return fetch(
                `${URI_API}/assigned_functions`,
                getHeaders("DELETE", {
                    id: selectedNurse,
                })
            );
        });

        Promise.all(requests)
            .then((_) => {
                toast("Funcionalidad eliminada", {
                    type: "success",
                    containerId: "NFM",
                });
                props.closeComponent();
                clearFields();
            })
            .catch((error) => {
                console.log("Error al realizar las solicitudes:", error);
            });
    };

    const toggleSelectedNurse = (selectednurse_id: string) => {
        setSelectedNurses((prevNurses) => {
            if (prevNurses.includes(selectednurse_id)) {
                return prevNurses.filter(
                    (prevNurse) => prevNurse !== selectednurse_id
                );
            } else {
                return [...prevNurses, selectednurse_id];
            }
        });
    };

    const AssignedBody = () => {
        return (
            <div
                style={{
                    overflowY: "scroll",
                    height: "50vh",
                    scrollbarWidth: "thin",
                }}
            >
                <table style={{ width: "100%" }}>
                    <thead
                        style={{
                            borderBottom: "2px solid #E3E3E3",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "13px",
                            lineHeight: "17.76px",
                            color: "#797979",
                        }}
                    >
                        <tr style={{ textAlign: "justify" }}>
                            <th>&nbsp;</th>
                            <th>Profesional</th>
                            <th>Funcion</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignedFunctions
                            .sort((a: any, b: any) =>
                                a.nursename.localeCompare(b.nursename)
                            )
                            .map((assignedFunction) => {
                                let functionDate = moment(
                                    assignedFunction.date
                                ).format("YYYY-MM-DD");
                                return (
                                    <tr>
                                        <td>
                                            <input
                                                name="actions"
                                                type="checkbox"
                                                checked={selectedNurses.includes(
                                                    assignedFunction.id
                                                )}
                                                onClick={() =>
                                                    toggleSelectedNurse(
                                                        assignedFunction.id
                                                    )
                                                }
                                            ></input>
                                        </td>
                                        <td>{assignedFunction.nursename}</td>
                                        <td>{assignedFunction.functionname}</td>
                                        <td>{functionDate}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        );
    };

    const AssignBody = () => {
        return (
            <div>
                <div
                    style={{
                        marginTop: "5%",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "0.5vh",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "0.8vw",
                        }}
                    >
                        Profesional*
                    </div>
                    <select
                        name="profesional"
                        id="profesional"
                        style={{
                            width: "100%",
                            height: "4vh",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "2px solid #E6E6E6",
                        }}
                        value={selectedNurse}
                        onChange={(e) => setSelectedNurse(e.target.value)}
                    >
                        <option value=""></option>
                        {availableNurses
                            .sort((a: any, b: any) =>
                                a.nursename.localeCompare(b.nursename)
                            )
                            .map((nurse) => {
                                return (
                                    <option value={nurse.nurse_id}>
                                        {nurse.nursename}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div
                    style={{
                        marginTop: "5%",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "0.5vh",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "0.8vw",
                        }}
                    >
                        Función*
                    </div>
                    <select
                        name="profesional"
                        id="profesional"
                        style={{
                            width: "100%",
                            height: "4vh",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "2px solid #E6E6E6",
                        }}
                        value={selectedFunction}
                        onChange={(e) => setSelectedFunction(e.target.value)}
                    >
                        <option value=""></option>
                        {functions
                            .sort((a: any, b: any) =>
                                a.description.localeCompare(b.description)
                            )
                            .map((item) => {
                                return (
                                    <option value={item.id}>
                                        {item.description}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "0.5vh",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "0.8vw",
                        }}
                    >
                        Fecha
                    </div>
                    <input
                        disabled
                        style={{
                            borderRadius: "3px",
                            outline: "none",
                            border: "2px solid #E6E6E6 ",
                            paddingLeft: " 0.3vw",
                            color: "#BFBFBF",
                            height: "4vh",
                        }}
                        value={format(new Date(), "EEEE, d 'de' LLL 'de' y", {
                            locale: es,
                        })}
                    ></input>
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                open={props.showComponent}
                onClose={() => {
                    props.closeComponent();
                    clearFields();
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "55vw",
                        bgcolor: "#FFFFFF",
                        border: "1px solid transparent",
                        boxShadow: 24,
                        p: "2%",
                        borderRadius: "32px",
                        outline: "none",
                    }}
                >
                    <div className="modalTitleContainer">
                        <p className="modalTitle">Funciones</p>
                        <button
                            className="button-icon"
                            onClick={() => {
                                setSelectedFunction("");
                                setSelectedNurse("");
                                props.closeComponent();
                            }}
                        >
                            <CrossIcon fill="white"></CrossIcon>
                        </button>
                    </div>
                    <div style={{ padding: "15px " }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                fontFamily: "Manrope",
                                fontSize: "14px",
                                fontWeight: "700",
                                justifyContent: "center",
                                marginBottom: "2vh",
                            }}
                        >
                            <div
                                style={{
                                    cursor: "pointer",
                                    borderBottom:
                                        tabOption === "asignacion"
                                            ? "3px solid #62098C"
                                            : "",
                                    paddingBottom: "0.7vh",
                                    letterSpacing: "0.7px",
                                }}
                                onClick={() => setTabOption("asignacion")}
                            >
                                Asignación
                            </div>
                            <div
                                style={{
                                    marginLeft: "5%",
                                    cursor: "pointer",
                                    borderBottom:
                                        tabOption === "asignadas"
                                            ? "3px solid #62098C"
                                            : "",
                                    paddingBottom: "0.7vh",
                                    letterSpacing: "0.7px",
                                }}
                                onClick={() => setTabOption("asignadas")}
                            >
                                Asignadas
                            </div>
                        </div>
                        {tabOption === "asignacion" ? (
                            <AssignBody></AssignBody>
                        ) : (
                            <AssignedBody></AssignedBody>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "5%",
                        }}
                    >
                        <button
                            style={{
                                borderRadius: "16px",
                                border: "1px solid #E6E6E6",
                                backgroundColor: "#62098C",
                                color: "#FFFFFF",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                                lineHeight: "16.39px",
                                textAlign: "center",
                                padding: "1% 5% 1% 5%",
                                cursor: "pointer",
                            }}
                            type="button"
                            onClick={
                                tabOption === "asignacion"
                                    ? assignFunction
                                    : deleteFunction
                            }
                        >
                            {tabOption === "asignacion" ? "Aceptar" : "Baja"}
                        </button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                enableMultiContainer
                containerId={"NFM"}
                autoClose={2000}
                position="top-right"
            ></ToastContainer>
        </>
    );
}
