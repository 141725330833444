import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { ReactComponent as ReemplazarIcon } from "../../assets/icons/reemplazar-icon.svg";
import { ReactComponent as EliminarIcon } from "../../assets/icons/eliminar-icon.svg";
import { ReactComponent as AgregarIcon } from "../../assets/icons/agregar-icon.svg";
import { ReactComponent as MoverIcon } from "../../assets/icons/mover-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { useContext, useEffect, useState } from "react";
import { ILoggedUser } from "../../store/interfaces";
import { AppState } from "../../store/reducer";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

export default function AssistanceAdjustNurses(props: any) {
    const {
        nurses,
        shifts,
        selectedShift,
        setSelectedShift,
        setSelectedNurse,
        selectedNurse,
        setSelectedNurses,
        selectedNurses,
    } = useContext(AssitanceAdjustViewContext);

    const [filteredNurses, setFilteredNurses] = useState(nurses);

    const loggedUser: ILoggedUser = useSelector(
        (state: AppState) => state.loggedUser
    );

    useEffect(() => {
        setFilteredNurses(nurses);
    }, [nurses]);

    const changeShiftCombo = (shift: any) => {
        setSelectedShift({ key: shift });
    };

    const filterNurses = (query: String) => {
        var filtered = nurses.filter(function (nurse: any) {
            if (query === "") {
                return true;
            } else if (
                nurse.nursename.toLowerCase().includes(query) ||
                nurse.classx.toLowerCase().includes(query) ||
                nurse.vital_support.toLowerCase().includes(query) ||
                nurse.area.toLowerCase().includes(query)
            ) {
                return true;
            }
            return false;
        });
        setFilteredNurses(filtered);
    };

    const updateSelectedNurses = (id: string) => {
        setSelectedNurses((prevNurses: any) => {
            if (prevNurses.includes(id)) {
                return prevNurses.filter((nurse_id: string) => nurse_id !== id);
            } else {
                return [...prevNurses, id];
            }
        });
    };

    return (
        <div
            style={{
                width: "100%",
                marginLeft: "1%",
                boxShadow:
                    "0px 8px 16px 2px rgba(0, 0, 0, 0.03), -8px -4px 16px 2px rgba(0, 0, 0, 0.03)",
                borderRadius: "12px 0px 0px 12px",
                paddingTop: "2vh",
                paddingInline: "2vw",
                overflow: "auto",
                height: "80vh",
                scrollbarWidth: "thin",
            }}
        >
            <div
                style={{
                    marginBlock: "2vh",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-end",
                }}
            >
                <button className="button-icon" onClick={props.closeSidebar}>
                    <CrossIcon fill="black" stroke="#797979" />
                </button>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                    }}
                >
                    <Tooltip title="Agregar" placement="top" arrow>
                        <button
                            style={{
                                marginRight: "1vw",
                            }}
                            className="button-icon"
                            onClick={() =>
                                props.openShowModal("Agregar Profesional")
                            }
                        >
                            <AgregarIcon></AgregarIcon>
                        </button>
                    </Tooltip>
                    <Tooltip title="Mover" placement="top" arrow>
                        <button
                            disabled={selectedNurses.length !== 1}
                            style={{
                                marginRight: "1vw",
                            }}
                            className="button-icon"
                            onClick={() =>
                                props.openShowModal("Mover Profesional")
                            }
                        >
                            <MoverIcon
                                fill={
                                    selectedNurses.length === 1
                                        ? "#62098C"
                                        : "#797979"
                                }
                            ></MoverIcon>
                        </button>
                    </Tooltip>
                    <Tooltip title="Reemplazar" placement="top" arrow>
                        <button
                            style={{
                                marginRight: "1vw",
                            }}
                            className="button-icon"
                            disabled={selectedNurses.length !== 1}
                            onClick={() =>
                                props.openShowModal("Reemplazar Profesional")
                            }
                        >
                            <ReemplazarIcon
                                fill={
                                    selectedNurses.length === 1
                                        ? "#62098C"
                                        : "#797979"
                                }
                            ></ReemplazarIcon>
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar" placement="top" arrow>
                        <button
                            style={{
                                marginRight: "1vw",
                            }}
                            className="button-icon"
                            disabled={selectedNurses.length === 0}
                            onClick={() =>
                                props.openShowModal("Eliminar Profesional")
                            }
                        >
                            <EliminarIcon
                                fill={
                                    selectedNurses.length > 0
                                        ? "#62098C"
                                        : "#797979"
                                }
                            ></EliminarIcon>
                        </button>
                    </Tooltip>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                    }}
                >
                    <div className="sectorLabel">Turno</div>
                    <select
                        className="sectorSelect"
                        value={selectedShift?.key}
                        onChange={(e: any) => changeShiftCombo(e.target.value)}
                    >
                        {shifts.map((shift: any) => {
                            return (
                                <option value={shift.key}>{shift.text}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div
                style={{
                    marginBlock: "2vh 1vh",
                }}
            >
                <input
                    style={{
                        width: "100%",
                        height: "3vh",
                        borderRadius: "12px",
                        border: "2px solid #E6E6E6",
                        paddingLeft: "0.8vw",
                        color: "#797979",
                        fontFamily: "Manrope",
                        fontSize: "0.9vw",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        outline: "none",
                    }}
                    type="text"
                    placeholder="Buscar..."
                    onChange={(event) =>
                        filterNurses(event.target.value.toLowerCase())
                    }
                ></input>
            </div>
            {filteredNurses.length > 0 ? (
                <table width="100%">
                    <thead>
                        <td onClick={() => {
                            if (selectedNurses.length > 0) {
                                setSelectedNurses([]);
                            } else {
                                filteredNurses.forEach((nurse: any) => { 
                                    updateSelectedNurses(nurse.id);
                                });
                            }
                        }}>
                            <input
                                type="radio"
                                checked={selectedNurses.length === filteredNurses.length}
                            />
                        </td>
                        <td>Profesional</td>
                        <td>Clase</td>
                        <td>Soporte Vital</td>
                        <td>Sector</td>
                        {loggedUser.position === "nura_super" && (
                            <td>Hospital</td>
                        )}
                    </thead>
                    <tbody>
                        {filteredNurses
                            .sort((a: any, b: any) =>
                                a.nursename.localeCompare(b.nursename)
                            )
                            .sort((a: any, b: any) =>
                                a.area.localeCompare(b.area)
                            )
                            .map((e: any) => {
                                return (
                                    <tr
                                        onClick={() =>
                                            updateSelectedNurses(e.id)
                                        }
                                    >
                                        <td>
                                            <input
                                                type="radio"
                                                checked={selectedNurses.includes(
                                                    e.id
                                                )}
                                            />
                                        </td>
                                        <td>{e.nursename}</td>
                                        <td>{e.classx}</td>
                                        <td>{e.vital_support}</td>
                                        <td>{e.area}</td>
                                        {loggedUser.position === "nura_super" && (
                                            <td>{e.hospital_name}</td>
                                        )}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            ) : (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#797979",
                        fontFamily: "Manrope",
                        fontSize: "0.8vw",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                    }}
                >
                    Sin profesionales
                </div>
            )}
        </div>
    );
}
